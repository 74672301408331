var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "690px", "max-height": "730px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v("IoT " + _vm._s(_vm.$t("msg.NEWB010P050.001"))),
        ]),
        _c("div", { staticClass: "content_box mt0 beforetab" }, [
          _vm.serviceLang === "KOR"
            ? _c("img", { attrs: { src: "/img/iotRmkKor.png" } })
            : _c("img", { attrs: { src: "/img/iotRmkEng.png" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }