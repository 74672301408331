<template>
  <div class="popup_wrap" style="width:690px;max-height: 730px;">
    <!--  popup_wrap -->
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">IoT {{ $t('msg.NEWB010P050.001') }}</h1>
      <div class="content_box mt0 beforetab">
         <img v-if="serviceLang === 'KOR'" src="/img/iotRmkKor.png"/>
         <img v-else src="/img/iotRmkEng.png"/>
      </div><!--content_box -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import store from '@/store'

export default {
  name: 'ContainerIotPop',
data () {
    return {
//
    }
  },
  computed: {
    serviceLang () {
      return store.getters['auth/serviceLang']
    }
  }
}
</script>

<style scoped>

</style>
